import React from 'react';
import { Box, Button, Flex, Input, InputGroup, InputRightElement,useMediaQuery } from '@chakra-ui/react';
import { SubTitle } from "../uiComponents/Text";

const JoinCommunity = ({ from }) => {
  const handleJoinClick = () => {
    window.open('https://forms.office.com/r/YOUR_FORM_ID', '_blank');  // Replace with your Microsoft Form link
  };
  const [isMobileView] = useMediaQuery("(max-width: 991px)");

  return (
    <Flex
      justifyContent="center"
      flexDirection={'row'}
      alignItems="center"
      width={{ base: '90%', md: '80%', lg: '80%' }}
      // zIndex={'99'}
      mt={from === "home" ? 20 : 10}
      bgGradient="linear(to-b, #7ce0f9, #94fed7)"
      marginBottom={from === "home" && !isMobileView ? "8%" : "0"}
      borderRadius={isMobileView? 'md':'full'}
      p={'20px'}
      display={isMobileView? 'inline-block': "flex"}
    >
      <Box>
      <SubTitle fontSize={isMobileView ? "xl":"2xl"} color="gray.700" mr={20}>
        Join our community
      </SubTitle>
      </Box>
      <Box display={"flex"} alignItems="center" borderRightRadius="none" w={isMobileView?"100%":"60%"} mt={isMobileView?3:0}>
        <InputGroup>
          <Input
            placeholder="Please enter your email address"
            borderRadius="full"
            bg="white"
            _placeholder={{ color: 'gray.500' }}
            size={isMobileView? "sm":"lg"}
            w={'85%'}
          />
          <InputRightElement  w={"50%"} pl={4}
              pr={4}  marginRight={0}>
            <Button
              size={isMobileView? "sm":"lg"}
              w={isMobileView? "50%" :'60%' }
              mt={isMobileView? -2: 2}
              borderBottomLeftRadius="full"
              colorScheme="#3F3B3B"
              bg={"#3F3B3B"}
              borderBottomRightRadius="full"
              borderTopRightRadius="full"
              onClick={handleJoinClick}
              pl={4}
              pr={4}
            >
              Join
            </Button>
          </InputRightElement>
        </InputGroup>
      </Box>
    </Flex>
  );
};

export default JoinCommunity;
