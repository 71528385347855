import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
// theme imports
import { ChakraProvider, useColorMode } from "@chakra-ui/react";
import AppRoutes from "./routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import generateTheme from "./theme/index"; 
import './i18n';
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

// Disable react devTools in production
if (process.env.NODE_ENV === "production") disableReactDevTools();

const queryClient = new QueryClient();

// Main component to apply the theme dynamically
const MainApp = () => {
  const colorMode = localStorage.getItem("chakra-ui-color-mode");
  console.log(colorMode," my theme")
  const theme = generateTheme(colorMode); // Pass the colorMode to generate the theme


  return (
    <ChakraProvider theme={theme}>
      <AppRoutes />
    </ChakraProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <QueryClientProvider client={queryClient}>
    <MainApp />
    <ReactQueryDevtools initialIsOpen={false} buttonPosition={"bottom-right"} />
  </QueryClientProvider>
);

reportWebVitals();
