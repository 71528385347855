import React from "react";
import {
  Box,
  Heading,
  Image,
  Text,
  VStack,
  useTheme,
  useBreakpointValue,
  Link,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { SubTitle } from "../uiComponents/Text";

const Content = ({ content }) => {
  const { colors } = useTheme();
  return (
    <Box
      textAlign="left"
      position="relative"
      width="100%"
      pt={10}
      backgroundColor={"transparent"}
      justifyContent={"center"}
    >
      <Box
        height="auto"
        width={{ base: "90%", md: "80%", lg: "80%" }}
        backgroundColor={"transparent"}
        justifyContent={"center"}
        margin={"0 auto"}
      >
        <Heading size="lg" mb={4} bg={colors.primary[200]} textStyle="Title">
          {content.title}
        </Heading>
        {/* <Heading as="h4">
                    {content.question}
                </Heading> */}

        {content.description.map((item, index) => (
          <Box key={index}>
            {item.title !== "" && (
              <Heading
                as="h4"
                fontSize="lg"
                width={"fit-content"}
                bg={index === 0 ? colors.primary[100] : index === 1 ? colors.secondary[100] : index === 2 ? colors.accent[100]: colors.green[100] }
                pl={3}
                pr={3}
                pt={2}
                pb={2}
                color={colors.primary[300]}
                borderRadius={'xl'}
              >
                {item.title}
              </Heading>
            )}
            {item.descr.map((d) => (
              <Text
                key={index}
                textAlign="left"
                fontSize="md"
                color="gray.700"
                mb={4}
              >
                {d}
              </Text>
            ))}
            {item.image !== "" && (
              <Image
                src={`${item.image}`}
                alt="item.image"
                width={item.image.includes("descr")?"40%": "60%"}
                objectFit="contain"
                borderRadius="md"
              />
            )}
          </Box>
        ))}

        <Heading size="lg" mb={4} bg={colors.primary[200]} textStyle="Title">
          {content.financialSupportTitle}
        </Heading>
        {content.financialSupportDescription.map((item, index) => (
          <Box>
            <Text
              key={index}
              textAlign="left"
              fontSize="md"
              color="gray.700"
              mb={4}
            >
              {item.descr}: <Link href={`${item.url}`}>{item.url}</Link>
            </Text>

            {item.image !== "" && (
              <Image
                src={`${item.image}`}
                alt="slide"
                width="100%"
                objectFit="cover"
                borderRadius="md"
              />
            )}
          </Box>
        ))}
      </Box>

      <VStack
        align="left"
        mt={10}
        bgImage={"url(`${illustration})"}
        backgroundSize={"cover"}
        width="90%"
        backgroundRepeat={"no-repeat"}
        backgroundColor={"transparent"}
      >
        <Box width="90%">
          {/* <Image
                src="slide.png"
                alt="slide"
                width="100%"
                objectFit="cover"
                borderRadius="md"
            /> */}
        </Box>
      </VStack>
    </Box>
  );
};

export default Content;
