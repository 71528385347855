import React from 'react';
import { VStack, Text } from '@chakra-ui/react';
import HeroSection from '../components/common/HeroSection';
import Content from '../components/home/Content';
import VideoSection from '../components/home/VideoSection';
import JoinCommunity from '../components/common/JoinCommunity';
import { useTranslation } from "react-i18next";
import {useMediaQuery}  from "@chakra-ui/react";

const Home = () => {
  const { t } = useTranslation();
  const selectiveMutism = t('selectiveMutism', { returnObjects: true })
  const [isMobileView] = useMediaQuery("(max-width: 991px)");

  return (
    <VStack spacing={0}>
      <HeroSection />
     {isMobileView && <JoinCommunity />}
      <Content content={selectiveMutism} />
      {/* <VideoSection /> */}
      {!isMobileView &&<JoinCommunity />}
    </VStack>
  );
};

export default Home;
