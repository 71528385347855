import React from 'react';
import { VStack, Text } from '@chakra-ui/react';
import HeroSection from '../components/common/HeroSection';
import Content from '../components/kidssmass/Content';
import VideoSection from '../components/home/VideoSection';
import JoinCommunity from '../components/common/JoinCommunity';
import { useTranslation } from "react-i18next";

const KidsSMASS = () => {
    const { t } = useTranslation();
    const content = t('KIDSSMASS', { returnObjects: true });
    return (
        <VStack spacing={0}>
            <HeroSection />
            <JoinCommunity from={"kidssmass"}/>
            <Content content={content} />
        </VStack>
    );
};

export default KidsSMASS;
