import {
  Box,
  Flex,
  Heading,
  Text,
  Avatar,
  IconButton,
  Button,
  Image,
  useStyleConfig,
  Card,
  CardHeader,
  CardBody,
  SimpleGrid,
  CardFooter,
} from "@chakra-ui/react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { BiLike, BiChat, BiShare } from "react-icons/bi";

const CardComponent = ({
  title,
  avatarName,
  avatarSrc,
  headerText,
  bodyText,
  imageSrc,
  footerActions,
  ...props
}) => {
  const styles = useStyleConfig("Card", props);

  return (
    <SimpleGrid
      spacing={4}
      templateColumns="repeat(auto-fill, minmax(200px, 1fr))"
    >
      <Card>
        <CardHeader>
          <Box>
            <Heading size="sm">{title}</Heading>
            <Text>{headerText}</Text>
          </Box>{" "}
        </CardHeader>

        <Image objectFit="cover" src="imageSrc" alt="Chakra UI" />
        <CardBody>
          <Text>{bodyText}</Text>
        </CardBody>
      </Card>
    </SimpleGrid>
  );
};

export default Card;
