const darkColors = {
    primary: {
        100: "#b3f4fc", // lighter shade
        200: "#7ce0f9", // primary color
        300: "#53d4f0", // darker shade
      },
      secondary: {
        100: "#bffef0", // lighter shade
        200: "#94fed7", // secondary color
        300: "#6bf8c5", // darker shade
      },
      accent: {
        100: "#000000", // lighter yellow
        200: "#ffdd44", // main yellow
        300: "#ffd700", // darker yellow
      },
    
    }

export default darkColors