// lightColors.js
const lightColors = {
 primary: {
      100: "#d0f3f7", // lighter shade
      200: "#7ce0f9", // primary color
      300: "#3bc1e3", // darker shade
    },
    secondary: {
      100: "#e6fff9", // lighter shade
      200: "#94fed7", // secondary color
      300: "#6bf8c5", // darker shade
    },
    accent: {
      100: "#fffae3", // lighter yellow
      200: "#ffdd44", // main yellow
      300: "#ffd700", // darker yellow
    },
    green: {
      100: "#defae9", // lighter greeb
      200: "#bbfad4", // main green
      300: "#63e095", // darker green
    },
};
export default lightColors