import { extendTheme } from "@chakra-ui/react";
import darkColors from "./darkColors"; // No need to add index.js or .js again
import lightColors from "./lightColors";
import { Component } from "react";


const generateTheme = (colorMode) => {
  const selectedColors = colorMode === "dark" ? darkColors : lightColors;
  const theme = extendTheme({
    colors: selectedColors,

    fonts: {
      heading: "SF UI Text, system-ui, sans-serif", // Fun, kid-friendly font for headings
      body: "SF UI Text, system-ui, sans-serif",
    },
    textStyles: {
      Title: {
        fontSize: ["24px", "32px"], // Responsive font sizes
        fontWeight: "bold",
        background: `linear-gradient(to right, ${selectedColors.primary[300]}, ${selectedColors.accent[300]}, ${selectedColors.secondary[300]})`, // Gradient effect
        WebkitBackgroundClip: "text", // This clips the background to the text
        WebkitTextFillColor: "transparent", // Ensures the text color is transparent to show the gradient
        letterSpacing: "0.1em", // Clips gradient to text
      },
      subTitle: {
        fontSize: ["20px", "24px"], // Responsive font sizes
        fontWeight: "bold",
      },
      bodyText: {
        fontSize: ["16px", "18px"],
        color: "gray.700",
        lineHeight: "1.6",
      },
    },
    components: {
      CardComponent: {
        baseStyle: {
          padding: "20px",
          borderRadius: "md",
          boxShadow: "lg",
          color: "black",
        },
      },
      Button: {
        baseStyle: {
          fontWeight: "bold",
          borderRadius: "full",
        },
        sizes: {
          lg: {
            padding: "10px 20px",
            fontSize: "18px",
          },
        },
        variants: {
          solid: {
            bg: "primary.200",
            color: "white",
            _hover: {
              bg: "primary.300",
            },
          },
        },
      },
    },
  });
  return theme
};
export default generateTheme