import React from 'react';
import { VStack, Text } from '@chakra-ui/react';
import HeroSection from '../components/common/HeroSection';
import Content from '../components/autism/Content';
import VideoSection from '../components/home/VideoSection';
import JoinCommunity from '../components/common/JoinCommunity';
import { useTranslation } from "react-i18next";

const Autism = () => {
    const { t } = useTranslation();
    const content = t('autism', { returnObjects: true });
    console.log(content, 'content')

    return (
        <VStack spacing={0}>
            <HeroSection />
            <JoinCommunity from={"autism"}/>
            <Content content={content} />
        </VStack>
    );
};

export default Autism;
