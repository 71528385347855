import React from "react";
import {
  Box,
  Flex,
  Button,
  HStack,
  Image,
  Link as ChakraLink,
  Menu,
  MenuList,
  MenuItem,
  useMediaQuery,
  MenuButton,
  IconButton,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link as ReactRouterLink, useLocation } from "react-router-dom"; // Added useLocation
import { useTranslation } from "react-i18next";
import LanguageSwitch from './LanguageSwitch';
import { HamburgerIcon } from '@chakra-ui/icons';

const Navbar = () => {
  const { t } = useTranslation();
  const [isMobileView] = useMediaQuery("(max-width: 991px)");
  const location = useLocation(); // Hook to get the current route

  const linkBackground = useColorModeValue('primary.200', 'secondary.200'); // Light and dark theme background colors for active link
  const activeLinkStyle = {
    bg: linkBackground,
    bgGradient:"linear(to-b, primary.300, secondary.200)",
    fontWeight: "bold",
    padding: 10
  };

  return (
    <Box px={4}>
      <Flex h={16} alignItems="center" justifyContent="space-between">
        <HStack>
          <Image src="vitlogo.png" alt="Vitafluence" width={isMobileView ? 100 : 150} />
          <Image src="logopartner.png" alt="University" width={isMobileView ? 50 : 100} />
        </HStack>

        {!isMobileView && (
          <HStack>
            <HStack spacing={4}>
              <ChakraLink
                as={ReactRouterLink}
                to="/"
                {...(location.pathname === "/" ? activeLinkStyle : {})} // Apply active style
              >
                {t("navbar.home")}
              </ChakraLink>
              <ChakraLink
                as={ReactRouterLink}
                to="/selective-mutism"
                {...(location.pathname === "/selective-mutism" ? activeLinkStyle : {})}
              >
                {t("navbar.selective_mutism")}
              </ChakraLink>
              <ChakraLink
                as={ReactRouterLink}
                to="/autism"
                {...(location.pathname === "/autism" ? activeLinkStyle : {})}
              >
                {t("navbar.autism")}
              </ChakraLink>
              <ChakraLink
                as={ReactRouterLink}
                to="/kids-sm-ass"
                {...(location.pathname === "/kids-sm-ass" ? activeLinkStyle : {})}
              >
                {t("navbar.kidssmass")}
              </ChakraLink>
              <ChakraLink
                as={ReactRouterLink}
                to="/aboutus"
                {...(location.pathname === "/aboutus" ? activeLinkStyle : {})}
              >
                {t("navbar.aboutUs")}
              </ChakraLink>
              <ChakraLink
                as={ReactRouterLink}
                to="/aboutus/#contactus"
                {...(location.pathname === "/aboutus/#contactus" ? activeLinkStyle : {})}
              >
                {t("navbar.contactUs")}
              </ChakraLink>
            </HStack>
            <Button
              borderRadius={"full"}
              colorScheme="primary.300"
              variant="solid"
              ml={20}
            >
              {t("navbar.joinIn")}
            </Button>
          </HStack>
        )}

        {isMobileView && (
          <Menu zIndex={"10000"}>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<HamburgerIcon />}
              variant="outline"
            />
            <MenuList>
              <MenuItem>
                <ChakraLink
                  as={ReactRouterLink}
                  to="/"
                  color={"gray.600"}
                  {...(location.pathname === "/" ? activeLinkStyle : {})} // Mobile active style
                >
                  {t("navbar.home")}
                </ChakraLink>
              </MenuItem>
              <MenuItem>
                <ChakraLink
                  as={ReactRouterLink}
                  to="/selective-mutism"
                  color={"gray.600"}
                  {...(location.pathname === "/selective-mutism" ? activeLinkStyle : {})}
                >
                  {t("navbar.selective_mutism")}
                </ChakraLink>
              </MenuItem>
              <MenuItem>
                <ChakraLink
                  as={ReactRouterLink}
                  to="/autism"
                  color={"gray.600"}
                  {...(location.pathname === "/autism" ? activeLinkStyle : {})}
                >
                  {t("navbar.autism")}
                </ChakraLink>
              </MenuItem>
              <MenuItem>
                <ChakraLink
                  as={ReactRouterLink}
                  to="/kids-sm-ass"
                  color={"gray.600"}
                  {...(location.pathname === "/kids-sm-ass" ? activeLinkStyle : {})}
                >
                  {t("navbar.kidssmass")}
                </ChakraLink>
              </MenuItem>
              <MenuItem>
                <ChakraLink
                  color={"gray.600"}
                  as={ReactRouterLink}
                  to="/aboutus"
                  {...(location.pathname === "/aboutus" ? activeLinkStyle : {})}
                >
                  {t("navbar.aboutUs")}
                </ChakraLink>
              </MenuItem>
              <MenuItem>
                <ChakraLink
                  color={"gray.600"}
                  as={ReactRouterLink}
                  to="/aboutus/#contactus"
                  {...(location.pathname === "/aboutus/#contactus" ? activeLinkStyle : {})}
                >
                  {t("navbar.contactUs")}
                </ChakraLink>
              </MenuItem>
              <MenuItem>
                <Button
                  borderRadius={"full"}
                  colorScheme="primary.300"
                  variant="solid"
                  size={"md"}
                  alignSelf={"left"}
                  color={'white'}
                >
                  {t("navbar.joinIn")}
                </Button>
              </MenuItem>
              <MenuItem>
                <LanguageSwitch />
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      </Flex>
    </Box>
  );
};

export default Navbar;
