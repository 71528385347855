import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/common/Navbar.js';
import Footer from './components/common/Footer.js';
import Home from './pages/Home';
import Autism from './pages/Autism.js';
import KidsSMASS from './pages/KidsSMASS';
import AboutUs from './pages/AboutUs';

const AppRoutes = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/selective-mutism" element={<Home />} />
        <Route path="/autism" element={<Autism />} />
        <Route path="/kids-sm-ass" element={<KidsSMASS />} />
        <Route path="/aboutus" element={<AboutUs />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default AppRoutes;
