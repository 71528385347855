import { Text, Heading, Link as ChakraLink } from "@chakra-ui/react";

// Corrected Title component
const Title = ({ children, ...props }) => (
    <Heading as='h3' size='2xl' {...props}>
        {children}
    </Heading>
);

// Corrected SubTitle component
const SubTitle = ({ children, ...props }) => (
    <Heading as='h4' fontSize='xl' {...props}>
        {children}
    </Heading>
);

// Corrected Paragraph component
const Paragraph = ({ children, ...props }) => (
    <Text mb={6} {...props}>
        {children}
    </Text>
);

// Corrected Link component (renamed ChakraLink to avoid name conflict)
const CustomLink = ({ children, ...props }) => (
    <ChakraLink mb={4} href='https://chakra-ui.com' {...props}>
        {children}
    </ChakraLink>
);

export { SubTitle, Paragraph, Title, CustomLink as Link };
