import React, { useState } from "react";
import {
    Box,
    HStack,
    VStack,
    Text,
    Link as ChakraLink, Image, Flex, Heading, useMediaQuery
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import FAQModal from "./FAQModal";
import { useNavigate } from "react-router-dom";

const Footer = () => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [isMobileView] = useMediaQuery("(max-width: 991px)");

    const navigate = useNavigate();

    const handleCloseModal = () => {
        setIsOpen(false);
    };

    const handleOpenFAQModal = () => {
        setIsOpen(true);
    };

    return (
        <Box
            p={4}
            color="white"
            bgImage="url('ground.png')"
            bgSize="cover"
            bgPosition="center"
            // position="fixed"
            bottom={0}
            left={0}
            width="100%"
            zIndex={1000}
            paddingTop={50}
            position={'relative'}
            mt={isMobileView ? 10:100}
        >

            <HStack width={"100%"} justify="space-between" spacing={8} display={isMobileView?'inline-block': 'flex'}>
                <Flex  justifyContent={'space-between'} width={{ base: "100%", md: "50%", lg: "50%" }}
                >
                    <VStack align="start" width={"100%"}>
                        <Heading fontSize={'lg'}>Company</Heading>

                        <ChakraLink onClick={() => navigate("/aboutus")}>{t("About")}</ChakraLink>
                        <ChakraLink
                            onClick={handleOpenFAQModal}
                            cursor="pointer"
                            _hover={{ textDecoration: "underline" }}
                        >
                            FAQs
                        </ChakraLink>
                        <ChakraLink 
                        onClick={() => navigate("/aboutus")} 
                        _hover={{textDecoration:'underline'}} >
                            {t("Help & Support")}
                        </ChakraLink>
                    </VStack>

                    <VStack width={"100%"}>
                        <Heading fontSize={'lg'}>Contacts</Heading>
                        <ChakraLink href="https://instagram.com" isExternal>
                            Instagram
                        </ChakraLink>
                        <ChakraLink href="https://facebook.com" isExternal>
                            Facebook
                        </ChakraLink>
                        <ChakraLink href="https://linkedin.com" isExternal>
                            LinkedIn
                        </ChakraLink>
                    </VStack>
                </Flex>
                {!isMobileView &&
                    <Box
                        marginTop="-15%"
                        zIndex={'1000'}
                        justifyContent="center"
                        alignSelf="center"
                        width="100%"
                    >
                        <Image
                            src="bike.svg"
                            alt="tree"
                            boxSize="400px"
                            objectFit="contain"
                            textAlign="center"
                            margin="0 auto"
                        />
                    </Box>
                }

                <HStack justify="center" mt={4} spacing={8} alignItems="center">
                    <VStack align={isMobileView? "center":"end"} spacing={2}>
                        <Text>+phonenumber</Text>
                        <Text>+phonenumber</Text>
                        <ChakraLink href="mailto:contactemail@yahoo.com" isExternal>
                        contactemail@yahoo.com
                        </ChakraLink>
                        {/* <Text textAlign={isMobileView ? "center": "left"}></Text> */}
                    </VStack>
                </HStack>

            </HStack>
            <Text textAlign="center" mt={4}>
                © 2024 KIDS-SM-ASS Project
            </Text>
            <FAQModal isOpen={isOpen} onClose={handleCloseModal} />
        </Box>
    );
};

export default Footer;
