import React from "react";
import {
  Box,
  Heading,
  Image,
  Text,
  VStack,
  useTheme,
  UnorderedList,
  ListItem,
  OrderedList,
  Link,
  Card,
  CardHeader,
  CardBody,
  SimpleGrid,
  Stack,
} from "@chakra-ui/react";
import CardComponent from "../uiComponents/CardComponent";

const Content = ({ content = {}, contact = {} }) => {
  const { colors } = useTheme();
  console.log(contact, "contacts");
  return (
    <Box
      textAlign="left"
      position="relative"
      width="100%"
      mt={10}
      pt={10}
      backgroundColor={"transparent"}
    >
      <Box
        height="auto"
        width={{ base: "100%", md: "80%", lg: "80%" }}
        backgroundColor={"transparent"}
        justifyContent={"center"}
        margin={"0 auto"}
      >
        {/* Title */}
        <Heading size="lg" mb={4} bg={colors.primary[200]} textStyle="Title">
          {content?.title || "About us"}
        </Heading>

        {/* Description */}
        {content?.description?.map((cont, index) => (
          <Text
            key={index}
            textAlign="left"
            fontSize="md"
            color="gray.700"
            mb={10}
          >
            {cont.descr}
          </Text>
        ))}

        {/* <SimpleGrid
           spacing={4}
           templateColumns="repeat(auto-fill, minmax(300px, 1fr))"
         > */}
        {content.ourTeam?.map((team, index) => (
          <Card
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            bgGradient="linear(to-r, white, accent.100)"
            mb={10}
          >
            <Image
              objectFit="contain"
              src={team.image}
              alt="team"
              width={200}
              height={300}
              p={2}
            />

            <Stack>
              <CardBody>
                <Box>
                  <Heading size="sm" color={"primary.300"}>
                    {team.name}
                  </Heading>
                  <Text fontWeight={"500"} mb={10}>
                    {team.title}
                  </Text>
                </Box>{" "}
                <Text>{team.descr}</Text>
              </CardBody>
            </Stack>
          </Card>
        ))}
        {/* </SimpleGrid> */}

        {/* Join Section */}
        {content.join.map((jo, joinIndex) => (
          <Box key={joinIndex}>
            <Heading
              size="md"
              mb={4}
              p={2}
              pl={3}
              pr={3}
              pt={2}
              pb={2}
              color={colors.primary[300]}
              borderRadius={"xl"}

              width={"fit-content"}
            >
              {jo.title}
            </Heading>
            <Text textAlign="left" fontSize="md" color="gray.700" mb={4}>
              {jo.joinDescription}
            </Text>

            {jo.instructions.map((instructr, instructrIndex) => (
              <Box key={instructrIndex}>
                <Text textAlign="left" fontSize="md" color="gray.700" mb={4}>
                  {instructr.descr}
                </Text>
                {instructr?.instructr?.map((instruct, instructIndex) => (
                  <UnorderedList key={instructIndex}>
                    <ListItem>{instruct}</ListItem>
                  </UnorderedList>
                ))}
              </Box>
            ))}
          </Box>
        ))}

        {/* Get Involved */}
        {content.getInvolved.map((involve, involveIndex) => (
          <Box key={involveIndex}>
            <Text textAlign="left" fontSize="md" color="gray.700" mb={4}>
              {involve.title}
            </Text>
            {involve.instructions.map((instruction, instructionIndex) => (
              <OrderedList key={instructionIndex}>
                <ListItem>{instruction}</ListItem>
              </OrderedList>
            ))}

            {involve.how.map((h, hIndex) => (
              <Box key={hIndex}>
                <Heading
                  size="md"
                  mb={4}
                  p={2}
                  pl={3}
                  pr={3}
                  pt={2}
                  pb={2}
                  color={colors.primary[300]}
                  borderRadius={"xl"}
                  bg={
                    hIndex === 0
                      ? colors.primary[100]
                      : hIndex === 1
                        ? colors.secondary[100]
                        : hIndex === 2
                          ? colors.accent[100]
                          : colors.green[100]
                  }
                  width={"fit-content"}
                  mt={10}
                >
                  {h.title}
                </Heading>
                {
                  h.descr.map((desc, descIndex) =>
                    <Box key={descIndex}>
                      <Text
                        textAlign="left"
                        fontSize="md"
                        color="gray.700"
                        mb={4}
                      >
                        {desc.descr}: <Link href={`${desc.url}`}>{desc.url}</Link><b>{desc.bold}</b>
                      </Text>
                      {h.moreInfo.map((more, moreIndex) =>
                        <Box key={moreIndex}>
                          <Text
                            textAlign="left"
                            fontSize="md"
                            color="gray.700"
                            mb={4}
                          >
                            {more.descr} <Link textDecoration={'underline'} color="primary.300" href={more?.link.includes("@")? "mailto:"+`${more.link}`:`${more.link}`}>{moreIndex === 0 && descIndex === 0 ? "" : more.link} <b>{more.bold}</b></Link>
                          </Text>

                          {more.image !== "" && (
                            <Image
                              src={`${more.image}`}
                              alt="slide"
                              width="20%"
                              objectFit="cover"
                              borderRadius="md"
                              mb={10}
                            />
                          )}
                        </Box>
                      )}

                    </Box>
                  )
                }
              </Box>
            ))}
          </Box>
        ))}

        {/* Contact Us */}
        <Heading size="lg" mb={4} textStyle="Title" mt={20}>
          {contact?.title || "Contact Us"}
        </Heading>

        {contact?.contactUs?.map((cont, contIndex) => (
          <Box key={contIndex} id="contactus">
            <Text textAlign="left" fontSize="md" color="gray.700" mb={4}>
              {cont.question}
            </Text>
            <Text textAlign="left" fontSize="md" color="gray.700" mb={4}>
              {cont.description}
            </Text>
            <Link
              color={"primary.300"}
              fontWeight={"bold"}
              href={`mailto:${cont.contact}`}
            >
              {cont.contact}
            </Link>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Content;
