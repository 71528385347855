import React, { useState } from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons"; // This will be an icon for the dropdown
import { useTranslation } from "react-i18next";

const LanguageSwitch = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState("English");
  const [isMobileView] = useMediaQuery("(max-width: 991px)");

  const changeLanguage = (lang, language) => {
    i18n.changeLanguage(lang);
    setLanguage(language);
  };

  return (
    <Menu>
      <MenuButton
        size={isMobileView ? "sm" : "lg"}
        variant={isMobileView ? "outline" : "solid"}
        bg={isMobileView ? "transparent" : "white"}
        as={Button}
        rightIcon={<ChevronDownIcon color={"primary.300"}/>}
        _hover={{ color: "white" }}

      >
        <Text
          // fontSize={"30px"}
          _hover={{ color: "white" }}
          color={"primary.300"}
        >
          {language}{" "}
          {language === "English"
            ? "🏴󠁧󠁢󠁥󠁮󠁧󠁿"
            : language === "German"
            ? "🇩🇪"
            : language === "Arabic"
            ? "🇦🇪"
            : "🇵🇱"}
        </Text>
      </MenuButton>
      <MenuList>
        <MenuItem onClick={() => changeLanguage("en", "English")}>
          🏴󠁧󠁢󠁥󠁮󠁧󠁿 English
        </MenuItem>
        <MenuItem onClick={() => changeLanguage("de", "German")}>
          🇩🇪 German{" "}
        </MenuItem>
        {/* <MenuItem onClick={() => changeLanguage('ar', "Arabic")}>Arabic</MenuItem> */}
        {/* <MenuItem onClick={() => changeLanguage('pl', "pl")}>Polish</MenuItem> */}
      </MenuList>
    </Menu>
  );
};

export default LanguageSwitch;
