import React from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  useTheme, Menu,
  Flex,
  useMediaQuery,
  Link as ChakraLink,
  Button
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import LanguageSwitch from '../../components/common/LanguageSwitch';
import { Link as ReactRouterLink } from "react-router-dom";

const Content = ({ content, home }) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  const [isMobileView] = useMediaQuery("(max-width: 991px)");

  return (
    <Box
      textAlign="center"
      position="relative"
      width="100%"
      mt={isMobileView ? 5:10}
      pt={isMobileView ? 5:10}
      // bgImage="url('slide.svg')" 
      opacity={1.0}
    >
      {isMobileView &&
        <Flex flexDirection={'row'} justifyContent={'center'} mb={"10"}>
          <Button variant={'outline'} m={1} size="sm" borderStyle={'dashed'} borderColor={"primary.300"}>
            <ChakraLink fontSize={'sm'} as={ReactRouterLink} to="/autism" color={'gray.600'}>
              {t("navbar.autism")}
            </ChakraLink>
          </Button>
          <Button variant={'outline'} m={1} size="sm" borderStyle={'dashed'} borderColor={"primary.300"}>
            <ChakraLink fontSize={'sm'} as={ReactRouterLink} to="/kids-sm-ass" color={'gray.600'}>
              {t("navbar.kidssmass")}
            </ChakraLink>
          </Button >
          <Box bg="transparent" m={1}>
            <LanguageSwitch />
          </Box>
        </Flex>
      }

      <Box
        pl={"10%"}
        height="auto"
        width="90%"
        backgroundColor={"transparent"}
      // mt={"-10vh"}
      >
        <Text
          textAlign="left"
          fontSize="xl"
          color="gray.700"
          mb={4}
        >
          {t("homeDescription")}
        </Text>
      </Box>
      <Box
        pl={"10%"}
        height="auto"
        width="90%"
        backgroundColor={"transparent"}
      // mt={"-10vh"}
      >

        <Heading size="lg" mb={4} textStyle="Title" textAlign={'left'} mt={10}>
          {content.title}
        </Heading>
        {content.description.map((cont, index) => (
          <Text
            key={index}
            textAlign="left"
            fontSize="md"
            color="gray.700"
            mb={4}
          >
            {cont.descr}
          </Text>
        ))}
      </Box>

      <VStack
        align="left"
        mt={10}
        backgroundSize={"cover"}
        width="100%"
        backgroundRepeat={"no-repeat"}
        backgroundColor={"transparent"}
        zIndex={"1000"}
      >
        <Box width="90%">
          {/* <Image
                src="slide.png"
                alt="slide"
                width="100%"
                objectFit="cover"
                borderRadius="md"
            /> */}
        </Box>
      </VStack>
    </Box>
  );
};

export default Content;
