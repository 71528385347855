import React from 'react';
import { VStack, Text } from '@chakra-ui/react';
import HeroSection from '../components/common/HeroSection';
import Content from '../components/aboutus/Content';
import VideoSection from '../components/home/VideoSection';
import JoinCommunity from '../components/common/JoinCommunity';
import { useTranslation } from "react-i18next";

const AboutUs = () => {
    const { t } = useTranslation();
    const content = t('about', { returnObjects: true });
    const contact = t("contact", { returnObjects: true })
    console.log('about item', contact)
    return (
        <VStack spacing={0}>
            <HeroSection />
            <JoinCommunity from={"about"}/>
            <Content content={content} contact={contact}/>
        </VStack>
    );
};

export default AboutUs;
